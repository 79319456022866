import { merge, get, isArray, isNil, isEmpty, forOwn, set } from 'lodash';
/**
 * Generates mock data for a given schema.
 * @param {Object} schema - The schema definition for generating mock data.
 * @param {string} outputType - Specifies if the output should be an array or an object.
 * @returns {Array|Object} - Mock data generated according to the schema.
 */
function generateMockData(schema, outputType = 'object') {
  const randomWord = (length) => {
    const words = [
      'apple', 'banana', 'cherry', 'date', 'elderberry', 'fig', 'grape', 'honeydew',
      'kiwi', 'lemon', 'mango', 'nectarine', 'orange', 'papaya', 'quince', 'raspberry',
      'strawberry', 'tangerine', 'ugli', 'vanilla', 'watermelon', 'xigua', 'yam', 'zucchini'
    ];
    return Array.from({ length }, () => words[Math.floor(Math.random() * words.length)]).join(' ');
  };

  const generateMockValue = (type) => {
    switch (type) {
      case 'string':
        return randomWord(5);
      case 'number':
        return Math.floor(Math.random() * 6000) + 1;
      case 'boolean':
        return true;
      case 'array':
        return [];
      case 'object':
        return {};
      default:
        return 'N/A';
    }
  };

  // Create a base structure based on the output type
  const mockData = {};

  forOwn(schema, (field, key) => {
    const fieldName = field.value; // Use `field.value` as the correct JSON key name

    if (field.type === 'array') {
      // Create an array with a single mock object inside based on the schema
      mockData[fieldName] = [generateMockData(field.schema, 'object')];
    } else if (field.type === 'object') {
      mockData[fieldName] = generateMockData(field.schema, 'object');
    } else {
      mockData[fieldName] = generateMockValue(field.type);
    }
  });

  // If the expected output type is 'array', wrap the generated object in an array only once
  return outputType === 'array' ? [mockData] : mockData;
}

/**
   * Generates a mock JSON structure based on the schema definition.
   * @param {Array} schemaArray - Array of schema definitions.
   * @returns {Object} - An object with fake data based on the schema.
   */
export function createMockJson(schemaArray) {
  const mockJson = {};

  schemaArray.forEach((schema) => {
    // Generate mock data based on the schema's structure and output type
    const mockData = generateMockData(schema.schema, schema.outputType);

    const pathSegments = schema.value;

    // Assign the mock data directly to the correct location using Lodash's
    set(mockJson, pathSegments, mockData);
  });

  return mockJson;
}

// Builds nested object structure based on the path
function buildNestedObject(path, value) {
  const segments = path.split('.');
  return segments.reverse().reduce((acc, segment) => ({ [segment]: acc }), value);
}

// Higher-order function that captures rootData
function createTransformer(rootData) {
  // Retrieve field value based on schema field type
  function getFieldValue(schemaField, data) {
    if (schemaField.type === 'raw') {
      return schemaField.value; // Always return the raw value directly
    }

    const fieldValue = get(schemaField, 'value');

    if (typeof fieldValue === 'string' && fieldValue.includes('jmes')) {
      return get(rootData, fieldValue);
    }

    return get(data, fieldValue);
  }

  // Main data transformation function with outputType support
  function transformData(data, schema, outputType) {
    // Handle output type transformation
    if (outputType === 'array' && !isArray(data)) {
      data = [data];
    } else if (outputType === 'object' && isArray(data)) {
      data = data.length > 0 ? data[0] : {};
    }

    // Recursively process array data
    if (isArray(data)) {
      return data
        .map((item) => transformData(item, schema))
        .filter((item) => Object.keys(item).length > 0);
    }

    const result = {};

    forOwn(schema, (schemaField, key) => {
      const fieldValue = getFieldValue(schemaField, data);

      // Skip if fieldValue is undefined or empty (except for raw values)
      if (isNil(fieldValue) || (isArray(fieldValue) && isEmpty(fieldValue))) {
        return;
      }

      let transformedValue;

      // Handle transformation for 'array' and 'object' types directly
      if (schemaField.type === 'array') {
        transformedValue = transformData(fieldValue, schemaField.schema || {});
      } else if (schemaField.type === 'object') {
        transformedValue = transformData(fieldValue, schemaField.schema || {});
      } else {
        transformedValue = fieldValue; // Directly assign the fieldValue for other types, including 'raw'
      }

      // Ensure raw values are added even if they're empty or undefined
      if (!isEmpty(transformedValue) || typeof transformedValue === 'string' || schemaField.type === 'raw') {
        const nestedObject = buildNestedObject(key, transformedValue);
        merge(result, nestedObject); // Merge nested object into result
      }
    });

    return result;
  }

  return {
    transformData,
  };
}

/**
   * Dynamically builds the output structure according to the given schema path.
   * @param {Object} rootData - The root data object containing all possible data sources.
   * @param {Array} schemas - The array of schema definitions.
   * @returns {Object} - The final transformed output.
   */
export function createDocumentRawData(rootData, schemas) {
  const { transformData } = createTransformer(rootData); // Initialize transformer with rootData

  if (!isArray(schemas)) {
    schemas = [schemas];
  }

  return schemas.reduce((finalOutput, schema) => {
    const dataSource = get(rootData, schema.value);

    if (!dataSource) {
      console.warn(`Data not found for path: ${schema.value}`);
      return finalOutput;
    }

    // Transform the extracted data using the schema and handle `outputType`
    const transformedData = transformData(dataSource, schema.schema, schema.outputType);

    // Build the nested output structure according to the schema's path
    const schemaOutput = buildNestedObject(schema.path, transformedData);

    // Merge the schema output into the final output object
    return merge(finalOutput, schemaOutput);
  }, {});
}
