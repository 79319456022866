import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import { get, debounce, isNil, isArray, noop } from 'lodash';
import Toggle from 'react-toggle';
import Select, { Creatable } from 'react-select';
import VirtualizedSelect from 'react-virtualized-select';

const inputTypes = [{
  value: 'input',
  label: 'Standard'
}, {
  value: 'recipient',
  label: 'E-Mail aktivieren'
}];

class ConsultantSelectConfiguration extends React.Component {
  constructor(props) {
    super(props);
    const { item, language } = props;
    const defaults = get(item, `props.defaults.${language}`, []);

    let selected = null;
    if (defaults[0]) {
      selected = 'consultant';
      if (defaults[0] === 'current') selected = 'current';
      if (defaults[0] === 'customer') selected = 'customer';
    }

    this.state = {
      selected
    };

    this.onToggleMulti = this.onToggleMulti.bind(this);
    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
    this.onConsultantDefaultValueChange = this.onConsultantDefaultValueChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onIncludeExcludeConsultantsChange = this.onIncludeExcludeConsultantsChange.bind(this);
    this.onIncludeExcludeConsultantsLocationDescriptionChange = this.onIncludeExcludeConsultantsLocationDescriptionChange.bind(this);

    this.selectOptions = [
      { value: 'current', label: 'Aktueller Benutzer' },
      { value: 'customer', label: 'Hauptbetreuer des Kunden' },
      { value: 'consultant', label: 'Berater Auswahl' }
    ];
  }

  /**
   * Toggle the multi flag.
   * Reset defauls and debounce execution
   *
   * @return void
   */
  onToggleMulti() {
    const { id, editValueField } = this.props;
    // Reset defaults if multi changes
    editValueField(id, 'defaultValue', null);
    debounce(editValueField, 500)(id, 'multi');
  }

  /**
   * Handle field type change
   *
   * @param   {Object}  selected  Selected option
   *
   * @return  void
   */
  onTypeChange(selected) {
    const { id, editValueField } = this.props;
    const { value } = selected;

    editValueField(id, 'prop', { key: 'type', value });
  }

  /**
   * OnChange handler for select
   *
   * @return void
   */
  onDefaultValueChange(selected) {
    const { id, editValueField } = this.props;

    const value = (selected) ? selected.value : null;
    this.setState({ selected: value });

    if (value === 'current' || value === 'customer') {
      editValueField(id, 'defaultValue', { de: [value], fr: [value], en: [value] });
    } else {
      // If not today, reset defaults to null
      editValueField(id, 'defaultValue', null);
    }
  }

  /**
   * Change handler for default value dropdown
   *
   * @param  {Mixed} selected Array of selected values, selected option or null
   *
   * @return void
   */
  onConsultantDefaultValueChange(selected) {
    const { id, editValueField } = this.props;
    let value = (isArray(selected))
      ? (selected.length > 0) ? selected.map((s) => s.value) : null
      : (selected) ? [selected.value] : null;

    // We have to save defaults for all languages.
    // imho this only makes sense in case of input / textarea. Take same value for all
    // languages. Adjust this if customer wants different configuration for each language
    if (!isNil(value)) {
      value = {
        de: value,
        fr: value,
        en: value
      };
    }

    editValueField(id, 'defaultValue', value);
  }

  /**
   * Handle onChange of include or exclude consultants select
   *
   * @param   {String} key       Key to set (includeConsultants or excludeConsultants)
   * @param   {Array}  selected  Selected values from dropdown
   *
   * @return  void
   */
  onIncludeExcludeConsultantsChange(key, selected = []) {
    const {
      id,
      editValueField
    } = this.props;

    const value = get(selected, 'length', 0) > 0
      ? selected.map((s) => s.value)
      : undefined;

    editValueField(id, 'prop', { key, value });
  }

  /**
   * Handle onChange of include or exclude consultant location select
   *
   * @param   {String} key       Key to set (includeConsultantsLocationDescription or excludeConsultantsLocationDescription)
   * @param   {Array}  selected  Selected values from dropdown
   *
   * @return  void
   */
  onIncludeExcludeConsultantsLocationDescriptionChange(key, selected) {
    const {
      id,
      editValueField
    } = this.props;

    const value = get(selected, 'length', 0) > 0
      ? selected.map((s) => s.value)
      : undefined;

    editValueField(id, 'prop', { key, value });
  }

  render() {
    const { selected } = this.state;
    const { item, consultants, language } = this.props;
    const defaults = get(item, `props.defaults.${language}`, []);

    const consultantOptions = consultants.map((c) => ({
      value: c.id,
      label: `${c.firstName} ${c.lastName}, ${c.username}`
    }));

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Eingabefeld Art
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Eingabefeld Ausprägung wählen"
              clearable={false}
              name={get(item, 'props.id')}
              options={inputTypes}
              value={item.props.type}
              onChange={this.onTypeChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Mehrfachauswahl
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={get(item, 'props.multi') === undefined ? false : item.props.multi}
              onChange={this.onToggleMulti}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Berater einschliessen
          </Col>

          <Col sm={9}>
            <VirtualizedSelect
              value={get(item, 'props.includeConsultants', [])}
              options={consultantOptions}
              onChange={(...args) => this.onIncludeExcludeConsultantsChange('includeConsultants', ...args)}
              multi
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Berater anhand location description einschliessen
          </Col>

          <Col sm={9}>
            <Creatable
              multi
              placeholder="Location description eingeben"
              value={get(item, 'props.includeConsultantsLocationDescription', []).map((o) => ({ label: o, value: o }))}
              onChange={(...args) => this.onIncludeExcludeConsultantsLocationDescriptionChange('includeConsultantsLocationDescription', ...args)}
              closeOnSelect={false}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Berater ausschliessen
          </Col>

          <Col sm={9}>
            <VirtualizedSelect
              value={get(item, 'props.excludeConsultants', [])}
              options={consultantOptions}
              onChange={(...args) => this.onIncludeExcludeConsultantsChange('excludeConsultants', ...args)}
              multi
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Berater anhand location description ausschliessen
          </Col>

          <Col sm={9}>
            <Creatable
              multi
              placeholder="Location description eingeben"
              value={get(item, 'props.excludeConsultantsLocationDescription', []).map((o) => ({ label: o, value: o }))}
              onChange={(...args) => this.onIncludeExcludeConsultantsLocationDescriptionChange('excludeConsultantsLocationDescription', ...args)}
              closeOnSelect={false}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Standard Wert
          </Col>

          <Col sm={9}>
            <Select
              value={selected}
              options={this.selectOptions}
              onChange={this.onDefaultValueChange}
            />
          </Col>
        </FormGroup>

        {(selected && selected === 'consultant') && (
          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Berater
            </Col>

            <Col sm={9}>
              <VirtualizedSelect
                value={(item.props.multi) ? defaults : defaults[0]}
                options={consultantOptions}
                onChange={this.onConsultantDefaultValueChange}
                multi={item.props.multi}
              />
            </Col>
          </FormGroup>
        )}
      </React.Fragment>
    );
  }
}

ConsultantSelectConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  consultants: PropTypes.array,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

ConsultantSelectConfiguration.defaultProps = {
  editValueField: noop,
  consultants: [],
  language: 'de'
};

export default ConsultantSelectConfiguration;
