import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { get } from 'lodash';
import copy from 'copy-to-clipboard';

import './ComponentIdMap.css';

const ComponentIdMap = React.memo(({ components, language }) => {
  const [collapsed, toggle] = useState(true);
  const [copied, setCopied] = useState(undefined);

  function onCopy(value) {
    setCopied(value);
    copy(value);

    setTimeout(() => setCopied(undefined), 500);
  }

  const children = components.map((component) => {
    const id = get(component, 'props.id');

    return (
      <ListGroupItem key={id}>
        <div
          className="workguide-actions-component-id-map--list-item"
          onClick={() => onCopy(id)}
        >
          <div className="workguide-actions-component-id-map--list-item-part">
            {get(component, `props.title.${language}`)}
          </div>

          <div className="workguide-actions-component-id-map--list-item-part">
            {id}
          </div>

          <div className="workguide-actions-component-id-map--list-item-part--action">
            <span
              className={copied === id ? 'mdi mdi-clipboard-check' : 'mdi mdi-clipboard-outline'}
              onClick={() => onCopy(id)}
            />
          </div>
        </div>
      </ListGroupItem>
    );
  })

  return (
    <div className="workguide-actions-component-id-map">
      <div
        className="workguide-actions-component-id-map--title"
        onClick={() => toggle(!collapsed)}
      >
        <div>
          Mapping von Komponenten zu Komponenten ID's
        </div>

        <div>
          <span className={collapsed ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />
        </div>
      </div>

      {!collapsed && (
        <React.Fragment>
          <p style={{ whiteSpace: 'pre-line' }}>
            Da das manuelle Ändern von Komponenten ID's zu diversen Problemen führen kann (Duplikate, bestehende Abhängigkeiten...),
            werden diese durch das System generiert.
          </p>

          <p>
            Die generierten ID's sind jedoch etwas kryptisch.
          </p>

          <p>
            Um die Erfassung von Post Actions (mappings) zu erleichtern, werden diese hier als Übersicht angezeigt.
          </p>

          <p>
            Mit Klick auf die Zeile oder das <span /> Icon können diese direkt in die Zwischenablage kopiert werden.
          </p>

          <ListGroup className="workguide-actions-component-id-map--list">
            {children}
          </ListGroup>
        </React.Fragment>
      )}
    </div>
  );
});

ComponentIdMap.propTypes = {
  components: PropTypes.array.isRequired,
  language: PropTypes.string
};

ComponentIdMap.defaultProps = {
  language: 'de'
};

export default ComponentIdMap;
