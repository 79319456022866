import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { loginRootSaga, logoutRootSaga } from '@evoja-web/react-login';
import { rootSaga as textblockRootSaga } from '@evoja-web/react-core-textblock';
import { rootSaga as applicationReleaseRootSaga } from '@evoja-web/react-application-release';

import * as banklistActions from '../actions/BanklistActions';
import * as interestActions from '../actions/InterestActions';
import * as idActions from '../actions/InvestmentProductActions';
import * as workguideActions from '../actions/WorkguideActions';
import codeMappingActions from '../actions/CodeMappingActions';
import environmentRootSaga from './Environment/root';
import featureFlagRootSaga from './FeatureFlag/featureFlagRootSaga';
import leadHistoryRootSaga from './LeadHistory/leadHistoryRootSaga';
import gravitonRootSaga from './Graviton/root';
import { rootSaga as moduleRootSaga } from '../packages/Module/index';
import { rootSaga as paramTransferRootSaga } from '../packages/ParamTransfer/index';
import { rootSaga as leadMassMutationRootSaga } from '../packages/LeadMassMutation/index';

import codeSagas from './codeSagas';
import jobProcessingSagas from './JobProcessing/root';
import leadSwitchSagas from './leadSwitchSagas';
import leadqualificationSagas from './leadqualificationSagas';
import loaderTriggerSagas from './LoaderTrigger/loaderTriggerSagas';
import workguideSagas from './workguideSagas';
import { INIT_ACTIONS } from '../actions/InitActions';
import { analyticsLogsSagas } from './AnalyticsLogs/analyticsLogsSagas';
import { analyticsSagas } from './Analytics/analyticsSagas';
import { analyticsUserSummarySagas } from './AnalyticsUserSummary/analyticsUserSummarySagas';
import { apiClientSagas } from './ApiClient/apiClientSagas';
import { bankListSagas } from './BankList/bankListSagas';
import { basicProductsSagas } from './BasicProducts/basicProductsSagas';
import { bulkLoadsSagas } from './BulkLoads/bulkLoadsSagas';
import { consultantsSagas } from './Consultant/consultantsSaga';
import { coreConfigSagas } from './CoreConfig/coreConfigSagas';
import { emailSubscriptionsSagas } from './EmailSubscriptions/emailSubscriptionsSagas';
import { externalLinksSagas } from './ExternalLinks/externalLinksSagas';
import { financingProfilingSagas } from './FinancingProfiling/financingProfilingSagas';
import { financingRatesSagas } from './FinancingRates/financingRatesSagas';
import { holidaysSagas } from './Holidays/holidaysSagas';
import { initSaga } from './initSaga';
import { leadsSwitchImportSagas } from './LeadsSwitchImport/leadsSwitchImportSagas';
import { noticeImportSagas } from './NoticeImport/noticeImportSagas';
import { permissonGroupSagas } from './PermissionGroup/permissionGroupSagas';
import { promotionsSagas } from './Promotions/promotionsSagas';
import { refErrorsSagas } from './RefErrors/refErrorsSagas';
import { setManagementSagas } from './SetManagement/setManagementSagas';
import { surchargeRealestatetypesSagas } from './FinancingSurchargeRealestatetype/surchargeRealestatetypesSagas';
import { uiNotesSaga } from './UiNotes/uiNotesSaga';
import { workguideStatusSagas } from './WorkguideStatus/workguideStatusSagas';
import {
  getCodeMappings,
  initCodeMappingForm,
  saveCodeMappingForm,
  saveCodeMapping
} from './CodeMapping/index';
import replayAuditLogSagas from './replayAudtiLogSagas';

const {
  CODE_MAPPINGS_REQUEST,
  CODE_MAPPING_FORM_INIT_REQUEST,
  CODE_MAPPING_FORM_SAVE_REQUEST,
  CODE_MAPPING_SAVE_REQUEST
} = codeMappingActions;

function* rootSaga() {
  yield all([
    takeLatest(INIT_ACTIONS.REQUEST, initSaga),
    takeLatest(`${banklistActions.GET}_REQUEST`, banklistActions.getBanklist),
    takeLatest(`${idActions.GET}_REQUEST`, idActions.getInvestmentProducts),
    takeLatest(`${idActions.PUT}_REQUEST`, idActions.updateInvestmentProductsItems),
    takeLatest(`${workguideActions.GET_WORKGUIDE}_REQUEST`, workguideActions.getWorkguide),
    takeLatest(`${workguideActions.DELETE_WORKGUIDE}_REQUEST`, workguideActions.deleteWorkguide),
    takeLatest(`${workguideActions.GET_METADATA}_REQUEST`, workguideActions.getWorkguidesMetadata),
    takeLatest(`${workguideActions.WORKGUIDE_USAGE_REQUEST}`, workguideActions.fetchWorkguideUsage),

    takeLatest(`${interestActions.GET}_REQUEST`, interestActions.getInterests),
    takeLatest(`${interestActions.POST}_REQUEST`, interestActions.createInterest),
    takeLatest(`${interestActions.PUT}_REQUEST`, interestActions.updateInterest),
    takeLatest(`${interestActions.DEL}_REQUEST`, interestActions.deleteInterest),
    takeLatest(CODE_MAPPINGS_REQUEST, getCodeMappings),
    takeLatest(CODE_MAPPING_FORM_INIT_REQUEST, initCodeMappingForm),
    takeLatest(CODE_MAPPING_FORM_SAVE_REQUEST, saveCodeMappingForm),
    takeLatest(CODE_MAPPING_SAVE_REQUEST, saveCodeMapping),

    applicationReleaseRootSaga(),
    codeSagas(),
    environmentRootSaga(),
    featureFlagRootSaga(),
    gravitonRootSaga(),
    leadMassMutationRootSaga(),
    leadHistoryRootSaga(),
    leadSwitchSagas(),
    leadqualificationSagas(),
    loaderTriggerSagas(),
    loginRootSaga(),
    logoutRootSaga(),
    moduleRootSaga(),
    paramTransferRootSaga(),
    replayAuditLogSagas(),
    textblockRootSaga(),
    workguideSagas(),

    fork(consultantsSagas),
    fork(basicProductsSagas),
    fork(promotionsSagas),
    fork(surchargeRealestatetypesSagas),
    fork(holidaysSagas),
    fork(financingRatesSagas),
    fork(coreConfigSagas),
    fork(bankListSagas),
    fork(analyticsSagas),
    fork(analyticsLogsSagas),
    fork(analyticsUserSummarySagas),
    fork(externalLinksSagas),
    fork(apiClientSagas),
    fork(bulkLoadsSagas),
    fork(refErrorsSagas),
    fork(uiNotesSaga),
    fork(noticeImportSagas),
    fork(leadsSwitchImportSagas),
    fork(emailSubscriptionsSagas),
    fork(permissonGroupSagas),
    fork(workguideStatusSagas),
    fork(setManagementSagas),
    fork(financingProfilingSagas),
    fork(jobProcessingSagas),

    put(INIT_ACTIONS.request(null)),
  ]);
}

export default rootSaga;
