import React, { useCallback, useEffect, useRef, useState } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';
import Toggle from 'react-toggle';
import { isEmpty, has } from 'lodash';
import { Button } from 'react-bootstrap';
import { ModalBody, ModalDialog } from '../../Common/Modal';
import { createDocumentRawData, createMockJson } from '../lib/generateRawDocumentData';

const initJson = [
  {
    path: 'ANOTHER_STRUCTURE.ADDRESS_LIST',
    value: 'formData.otherData',
    outputType: 'object',
    schema: {
      ADDRESS_NAME: {
        value: 'title',
        type: 'string'
      },
      ADDRESS_PLZ: {
        value: 'postcode',
        type: 'number'
      },
      KD_ADR_KEYWORD: {
        value: 'keywords',
        type: 'array',
        schema: {
          KEY: {
            value: 'name',
            type: 'string'
          }
        }
      }
    }
  }
];

function DocumentData({ value, onChange, language }) {
  const [openEditor, setOpenEditor] = useState(!isEmpty(value));
  const [previewModalOpen, setOpenPreviewModal] = useState(false);
  const [json, setJson] = useState(value || initJson);
  const editorRef = useRef();

  // upadte json when language changes
  useEffect(() => {
    setJson(value || initJson);
    if (has(editorRef, 'current.jsonEditor')) {
      editorRef.current.jsonEditor.set(value || initJson);
    }
  }, [language]);

  const handleEditorChange = useCallback((data) => {
    setJson(data);
    onChange('documentRawData', 'documentRawData', data);
  }, [onChange]);

  const onToggle = useCallback(() => {
    const newOpenEditor = !openEditor;
    setOpenEditor(newOpenEditor);
    if (newOpenEditor) {
      handleEditorChange(initJson);
    } else {
      handleEditorChange(undefined);
    }
  }, [handleEditorChange, openEditor]);

  return (
    <div className="document-data--container">
      <Toggle
        checked={openEditor}
        onChange={onToggle}
      />
      {openEditor && (
        <>
          <Editor
            ref={editorRef}
            value={json}
            mode="code"
            ace={ace}
            onChange={handleEditorChange}
            mainMenuBar={false}
            htmlElementProps={{ style: { height: 600, width: 900 } }}
          />
          <Button style={{ marginTop: 10 }} onClick={() => setOpenPreviewModal(!previewModalOpen)}>
            Generate output example
          </Button>
        </>
      )}
      {previewModalOpen && (
      <ModalDialog
        show={previewModalOpen}
        onHide={() => setOpenPreviewModal(false)}
        bsSize="lg"
        className="analytics-history__modal"
      >
        <ModalBody>
          <Editor
            value={createDocumentRawData(createMockJson(json), json)}
            mode="code"
            ace={ace}
            mainMenuBar={false}
            htmlElementProps={{ style: { height: 600 } }}
          />
        </ModalBody>
      </ModalDialog>
      )}
    </div>
  );
}

export default DocumentData;
